<template>
	<div class="treelist" v-for="i in list" @contextmenu.prevent="nodeClick($event,i)" :key="i.branchId">
		<div class="nodebox" title="右键编辑">
			<img src="../assets/img/arright.png" alt />
		<div class="childpart">{{ i.branchName }} <span>({{i.userTotal}}人)</span></div>
		</div>
		<domtrrchild :list="i.child"></domtrrchild>
		
	</div>
</template>

<script setup>
	import {
		defineProps,
		inject
	} from 'vue';
	defineProps(['list']);
	let nodefn = inject('fn');
	function nodeClick(e,item){
		nodefn(e,item)
	}
</script>
<style lang="scss" scoped>
	.treelist{
		.childpart{
			cursor: pointer;
		}
	}
</style>
