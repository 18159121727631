<template>
<navBar :list="navlist" name="部门管理"></navBar>
<div class="department">

  <div class="listbox">
    <!-- <div class="top">重庆华有科技有限公司</div>
            <div class="bar">
                <img src="../assets/img/arrow.png" alt />
            </div>-->
    <div class="noepart" v-if="data.obj.length == 0">
      <div class="partname" title="右键编辑" @contextmenu.prevent="edit($event, { branchName: '', branchId: 0 })">
        <span>添加部门</span>
      </div>
    </div>
    <div class="partnamebox" v-else>
      <div class="partname" title="右键编辑" @contextmenu.prevent="edit($event, data.obj)">
        <span>{{ data.obj.branchName }}</span>
      </div>
    </div>
    <div class="through" v-if="data.obj.child && data.obj.child.length > 1"></div>
    <div class="childbox">
      <div class="child" v-for="item in data.obj.child" @contextmenu.prevent="edit($event, item)" title="右键编辑" :key="item.branchId">
        <span>
          {{ item.branchName }}
          <span style="margin-left: 10px;">({{ item.userTotal }}人)</span>
        </span>
        <domtree :list="item.child"></domtree>
      </div>
    </div>
  </div>
  <transition name="false" @click.stop="() => { }">
    <div class="menu" v-if="menushow" :style="position">
      <div class="item" @click="handleEdit">
        <img src="../assets/img/edit.png" />
        <span>修改名称</span>
      </div>
      <div class="item" @click="() => { addvasible = true; menushow = false }">
        <img src="../assets/img/add.png" />
        <span>添加子部门</span>
      </div>
      <div class="item" @click="deleted">
        <img src="../assets/img/deleted.png" />
        <span>删除部门</span>
      </div>
    </div>
  </transition>
</div>

<!--  -->
<el-dialog title="新增子部门" v-model="addvasible" width="30%" @close="addclear">
  <el-form :model="addform" ref="addforms">
    <el-form-item label prop="branchName" :rules="{ required: true, message: '请输入部门名称', trigger: 'blur' }">
      <el-input v-model="addform.branchName" placeholder="输入部门名称" class="public-input"></el-input>
    </el-form-item>
  </el-form>
  <template #footer>
    <el-button type="default" @click="addvasible = false">取消</el-button>
    <el-button type="primary" @click="addDepartment">确认</el-button>
  </template>
</el-dialog>

<!--  -->
<el-dialog title="编辑" v-model="editvasible" width="30%" @close="addclear">
  <el-form :model="departItem">
    <el-form-item label prop="branchName" :rules="{ required: true, message: '请输入部门名称', trigger: 'blur' }">
      <el-input v-model="departItem.branchName" placeholder="输入名称" class="public-input"></el-input>
    </el-form-item>
  </el-form>
  <template #footer>
    <el-button type="default" @click="editvasible = false">取消</el-button>
    <el-button type="primary" @click="keepEdit">确认</el-button>
  </template>
</el-dialog>
</template>

<script setup>
import domtree from '@/components/domtrrchild.vue'
import {
  inject,
  reactive,
  ref,
  onMounted,
  provide
} from 'vue';
const vm = inject('plugin');
let navlist = [];
onMounted(() => {
  //阻止默认右键菜单
  document.querySelector('.department').oncontextmenu = function() {
    return false;
  };
  document.body.onclick = function() {
    menushow.value = false;
  };
});
//    ------------------------------
let data = reactive({
  obj: {}
});

function getList() {
  vm.HttpGet('/admin/branch/tree')
    .then(res => {
      if (res.data) {
        data.obj = res.data[0];
      }
    })
}
getList()
// ----------------------


//  菜单
let menushow = ref(false);
let position = reactive({
  left: '0px',
  top: '0px'
})
let departItem = reactive({
  branchId: '',
  branchName: ''
});

function edit(e, item) {
  e.stopPropagation();
  position.left = e.clientX + 'px';
  position.top = e.clientY + 'px';
  departItem.branchId = item.branchId;
  departItem.branchName = item.branchName
  menushow.value = true;
  return false
}

// 添加部门------------
provide('fn', edit)
let addvasible = ref(false);
let addform = reactive({
  branchName: '',
  branchImg: '',
  branchSort: 1,
  parentId: ''
});
let addforms = ref(null)

function addDepartment() {
  addform.parentId = departItem.branchId
  addforms.value.validate(async (valid) => {
    if (valid) {
      let res = await vm.HttpPost('/admin/branch/create', addform);
      if (res.code == 0) {
        menushow.value = false;
        addvasible.value = false;
        vm.message(res.msg);
        departItem = {};
        getList();
      }
    }

  })
}
// 清空数据
function addclear() {
  addform.branchName = '';
  departItem.branchId = '';
  departItem.branchName = '';
}

// 编辑------------
let editvasible = ref(false)

function handleEdit() {
  editvasible.value = true
  menushow.value = false;
}
// 保存修改
async function keepEdit() {
  let res = await vm.HttpPost('/admin/branch/update', {
    branchId: departItem.branchId,
    branchName: departItem.branchName,
    parentId: 1
  });
  if (res.code == 0) {
    editvasible.value = false;
    vm.message(res.msg)
    getList();
  }
}
// 删除 ------------
function deleted() {
  vm.confirm('确认删除该部门?', '提示').then(() => {
    vm.HttpPost('/admin/branch/delete', {
      id: departItem.branchId
    }).then(res => {
      vm.message(res.msg, 'success');
      menushow.value = false;
      getList();
    });
  });
}
</script>

<style lang="scss">
.department {
    display: inline-flex;
    justify-content: center;
    padding-top: 57px;
    box-sizing: border-box;
    // background: #ffffff;
    width: 100%;
    // height: 100%;
    .listbox {
        display: flex;
        flex-direction: column;
        align-items: center;
        .top {
            width: 320px;
            height: 48px;
            background: #3d7eff;
            border-radius: 24px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 48px;
            text-align: center;
        }
        .bar {
            width: 11px;
            img {
                width: 11px;
                height: 29px;
            }
            position: relative;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            &::before {
                content: "";
                display: block;
                width: 1px;
                height: 30px;
                background: #3d7eff;
            }
        }
        .through {
            width: calc(100% - 190px);
            height: 1px;
            background: #3d7eff;
            margin-top: 29px;
            position: relative;
            // left: 50%;
            transform: translate(-25px);
        }
        .noepart,
        .partnamebox {
            display: flex;
        }
        .partname {
            min-width: 140px;
            height: 32px;
            background: #3d7eff;
            border-radius: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            margin-right: 50px;
            padding: 0 10px;
            box-sizing: border-box;
        }
        .partnamebox {
            .partname {
                &::after {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 30px;
                    background: #3d7eff;
                    margin: 0 auto;
                }
            }
        }

        .childbox {
            display: inline-flex;
            margin-top: 34px;
            position: relative;
            .line {
                width: 1px;
                height: 100%;
                background-color: red;
                position: absolute;
            }
            .child {
                width: 140px;
                height: 32px;
                background: #8fb5ff;
                border-radius: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
                line-height: 32px;
                position: relative;
                box-sizing: border-box;
                // margin-left: 50px;
                margin-right: 50px;
                cursor: pointer;
                &::before {
                    content: "";
                    display: block;
                    background: url("../assets/img/arrow.png") no-repeat;
                    background-size: 11px 29px;
                    width: 11px;
                    height: 29px;
                    position: absolute;
                    top: -100%;
                    left: 50%;
                    transform: translate(-50%);
                }
                .treelist {
                    margin-left: 50px;
                    margin-top: 26px;
                    .nodebox {
                        position: relative;
                    }
                    // z-index: 99;
                    img {
                        width: 15px;
                        height: 53px;
                        position: absolute;
                        left: -15px;
                        bottom: 50%;
                        transform: translateY(10%);
                        // z-index: -1;
                    }
                    .childpart {
                        width: 140px;
                        height: 32px;
                        background: #f5f5f5;
                        border-radius: 16px;
                        text-align: center;
                        line-height: 32px;
                        margin-top: 15px;
                        color: #333333;
                    }
                }
            }
        }
    }
    .menu {
        width: 122px;
        height: 122px;
        background: url("../assets/img/menubg.png") no-repeat;
        background-size: 100% 100%;
        position: fixed;
        padding-left: 27px;
        box-sizing: border-box;
        transition: all 0.2s;

        .item {
            height: 38px;
            line-height: 38px;
            font-size: 12px;
            cursor: pointer;
        }

        img {
            width: 12px;
            height: 12px;
            margin-right: 10px;
        }
    }
}
</style>
